input, textarea, select, button {
  font-size: 16px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif 
}

.cont {
  width: 100%;
  min-height: 100vh;
  background-color: grey;
  position: fixed;
  z-index: -1;
}

.window {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
  margin: auto;
  align-items: center;
  justify-content: start;
  height: 100vh;
  background-image: url("./image//backGround4.JPG");
  background-size: cover;
  z-index: -1;
  color: white;
}
.contHead {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    padding-top: 20px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.textes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 7%;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 15px;
  background-image: url("./image/paint.png");
  background-size: 100% 100%;
  margin-left: -2%;

}
.headText1 {
    color: rgb(255, 255, 255);
    font-size: 28px;
    margin: 0px;
    text-shadow: -1px -1px 1px rgb(0, 0, 0, 0.3);
    font-weight: 600;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif 
}
.downText {
  font-size: 12px;
  color: rgb(184, 184, 184);
  margin: 0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif 
}
.head {
  position: relative;
  z-index: 2;
  margin: auto;
  border-radius: 20px;
  margin-bottom: 10px;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background-color: black; */

}
.img1{
  width: 40px;
  height: 40px;
}
.img2{
  position: absolute;
  left: 50px;
  width: 25px;
  height: 25px;
  animation: right 8s infinite ease-in-out;
}
@keyframes right {
  0% {
    left: 165px;
    top:-58px;
  }
  20% {
    left: 165px;
    top:-58px;
  }
  60% {
    left: 60px;
    top:10px;
  }
  100% {
    top:10px;
    left: calc(100% - 50px - 40px); /* Конечная точка: 50px от правого края, вычитаем ширину элемента */
  }

}
.img3{
  width: 40px;
  height: 40px;
}
.textUp{
  margin: auto;
  text-shadow: -1px -1px 1px rgb(0, 0, 0, 0.3);
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
  font-size: 20px;
}
.start-container {
  background: rgb(255, 255, 255);;
  margin-top: -20px;
  max-height: 65vh;
  padding: 30px 40px 30px 40px;
  border-radius: 20px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.2);
  width: 90%;
  box-sizing: border-box;
  text-align: center;
  color: #1a2a6c;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  overflow: auto; 
}
.start-container-anime {
  background: rgb(255, 255, 255);
  margin-top: -20px;
  max-height: 65vh;
  padding: 30px 40px 30px 40px;
  border-radius: 20px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.2);
  width: 90%;
  box-sizing: border-box;
  text-align: center;
  color: #1a2a6c;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
  overflow: auto;
  display: inline-block; /* Make sure the block can rotate independently */
  animation: rotateY1 0.7s linear
}
.textStartHead {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
.textStart {
    margin: 0;
    font-size: 15px;
}
.btn-start{
  background: linear-gradient(to right, #1a2a6c, #b21f1f, #fdbb2d);
  border: none;
  color: white;
  padding: 10px;
  border-radius: 20px;
  font-size: 1em;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif 
}
.form-container {
  background: white;
  padding: 10px 40px 30px 40px;
  border-radius: 20px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.2);
  width: 90%;
  box-sizing: border-box;
  text-align: center;
  color: #1a2a6c;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  overflow: auto; 
}
.form-container-anime {
  background: white;
  padding: 10px 40px 30px 40px;
  border-radius: 20px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.2);
  width: 90%;
  box-sizing: border-box;
  text-align: center;
  color: #1a2a6c;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
  overflow: auto;
  display: inline-block; /* Make sure the block can rotate independently */
  animation: rotateY2 0.7s linear
}
.form-group {
  margin-bottom: 15px;
}
.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box;
  color: #1a2a6c;
}
.form-group input::placeholder {
  font-size: 15px;
}
.form-group input:focus {
  outline: none;
  border:2px solid #fdbb2d
}
.keep-signed-in {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #999;
  margin-top: 20px;
}
.create-button {
  background: linear-gradient(to right, #1a2a6c, #b21f1f, #fdbb2d);
  border: none;
  color: white;
  padding: 10px;
  border-radius: 20px;
  font-size: 1em;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif 
}
.contMessage {
  background: white;
  padding: 20px 30px 30px 30px;
  border-radius: 20px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.2);
  width: 90%;
  /* margin-top: -20px; */
  max-height: 65vh;
  box-sizing: border-box;
  text-align: center;
  color: #1a2a6c;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  overflow: auto; 

}
.headTextMes{
    font-weight: bold;
    font-size: 22px;
    margin: 0;

}
.textMessage {
    font-size: 19px;

}
.copySend {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}
.btnCopy {
  background-color: #b21f1f;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 20px;
  font-size: 1em;
  cursor: pointer;
  width: 40%;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
  cursor: pointer;
}
.textModal {
  position: absolute;
  width: 40%;
  box-sizing: border-box;
  left: 0px;
  top:-40px;
  background-color: white;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 15px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
  font-size: 13px;
}
.btnAgain {
  background-color: #1a2a6c;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 20px;
  font-size: 1em;
  cursor: pointer;
  width: 40%;
  cursor: pointer;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif 
}

  @media screen and (max-width: 400px) {
    .start-container {
      padding-left: 25px;
      padding-right: 25px;
    }
    .start-container-anime {
      padding-left: 25px;
      padding-right: 25px;

    }
    .textStartHead {
      font-size: 15px;
    }
    .textStart {
      font-size: 14px;
    }
    .headTextMes {
      font-size: 18px;
    }
    .textMessage {
      font-size: 16px;
    }
    .form-group input::placeholder {
      font-size: 12.5px;
    }

  }
  @media screen and (max-width: 350px) {
    .form-group input::placeholder {
      font-size: 12px;
    }
  }
  @media screen and (max-height: 700px) {
    .start-container {
      margin-bottom: 20px;
    }
    .start-container-anime {
      margin-bottom: 20px;
      /* background-color: black; */
    }
    .form-container {
      margin-bottom: 20px;
      padding-top:0px;
      padding-bottom: 20px;
    }
    .form-container-anime {
      margin-bottom: 20px;
      padding-top:0px;
      padding-bottom: 20px;
    } 
    .contMessage {
      max-height: 60vh;
      padding: 10px 20px 10px 20px;
    }
  }
  @media screen and (max-height: 650px) {
  }

  @keyframes rotateY1 {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(90deg);
    }
  }
  @keyframes rotateY2 {
    0% {
      transform: rotateY(-90deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }

/* Для вебkit-браузеров (Chrome, Safari, Opera) */
::-webkit-input-placeholder {
    color: #999797;
    /* font-size: 15px; */
  }
  
  /* Для Firefox */
  :-moz-placeholder {
    color: #999797;
    /* font-size: 15px; */
  }
  
  /* Для Firefox 19+ */
  ::-moz-placeholder {
    color: #999797;
    /* font-size: 15px; */
  }
  
  /* Для IE 10+ */
  :-ms-input-placeholder {
    color: #999797;
    /* font-size: 15px; */
  }
  