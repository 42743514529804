.contAd {
    position: fixed;
    top:0;
    left:50%;
    transform: translateX(-50%);
    background-color: rgb(128, 128, 128, 0.8);
    width: 100%;
    max-width: 450px;
    height: 100%;
    z-index: 7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.windowAd {

  background: white;
  padding: 20px 50px 40px 50px;
  border-radius: 20px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.2);
  width: 80%;
  box-sizing: border-box;
  text-align: center;
  color: #1a2a6c;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    display: inline-block; /* Make sure the block can rotate independently */
    animation: rotateY2 0.7s linear;
}
.textAd {
    font-size: 20px;
    margin: 0;
    font-weight: bold;
    /* text-shadow: -1px -1px 2px black; */

}
@keyframes rotateY2 {
    0% {
      transform: rotateY(-90deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }
  @media screen and (max-width: 400px) {
    .textAd {
      font-size: 16px;
    }
  }




