.contBotton {
    position: absolute;
    height: 60px;
    bottom: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.btnMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: white;
    border: none;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.4);
    margin-right: 5px;
    cursor: pointer;
}
.btnMenu:hover {
    opacity: 0.7;
}
.imgMenu {
    width: 35px;
    height: 35px;
    cursor: pointer;
}
.modalAdd {
    position: absolute;
    width: 150px;
    top: -30px;
    background-color: white;
    padding: 2px 2px 2px 2px;
    border-radius: 5px;
    color: #1a2a6c;
    margin: 0px;
    box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.4);
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    z-index: 100;
}
@media screen and (max-height: 801px) {
    .contBotton {
        bottom: 10px;
    }

    .btnMenu{
        width: 50px;
        height: 50px;
    }
    .imgMenu {
        width: 30px;
        height: 30px;
    }
}
@media screen and (max-height: 620px) {
    .contBotton {
        bottom: 0px;
    }
}
@media screen and (min-height: 950px) {
    .contBotton {
        bottom: none;
        top:750px;
        /* background-color: black; */
    }
}